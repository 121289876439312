// src/PasswordScreen.js

import React, { useState } from 'react';
import './PasswordScreen.css';

const PasswordScreen = ({ onCorrectPassword }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === 'G7t#Q2z!Lm@9') { // Use the new strong password
      onCorrectPassword();
    } else {
      setError('Incorrect password');
    }
  };

  return (
    <div className="password-screen">
      <div className="password-form-container">
        <form className="password-form" onSubmit={handleSubmit}>
          <h1 className="title">Enter Password</h1>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="password-input"
            placeholder="Password"
          />
          <button type="submit" className="submit-button">Enter</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default PasswordScreen;
