import './App.css';
import React, { useState } from 'react'
import Logo from "./logo.png"
import Logo1 from "./logo1.png"
import PasswordScreen from './password/PasswordScreen';
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handleCorrectPassword = () => {
    setIsAuthenticated(true);
  };
  return (
    <>{isAuthenticated ? <div className="main">
      <div className="sentence-flow">
        <h1>Speak logic <br></br><span className="sentence-flow-text">Sentence Flow</span></h1>
        <img className="problem-icon" src={Logo1} alt=" " />
        <div onClick={() => { window.location.href = 'https://sentenceflow.slpsolver.net' }}  className="button">
          Get started
        </div>
      </div>
      <div className="problem-mapping">
        <h1>Speak logic <br></br><span className="problem">Problem</span> to <span className="solution">Solution</span><br></br> Mapping</h1>
        <img className="problem-icon" src={Logo} alt="" />
        <div onClick={() => { window.location.href = 'https://problemmapping.slpsolver.net' }} className="button">
          Get started
        </div>
      </div>
    </div> : <PasswordScreen onCorrectPassword={handleCorrectPassword} />
    }</>
  );
}

export default App;
